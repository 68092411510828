import React, { useContext, useEffect, useState } from "react";
import Wrapper from "./Components/Wrapper/Wrapper";
import SideImage from "./Components/SideImage/SideImage";
import { authImage, logo2 } from "../../Common/Assets/Images";
import Input from "../../Common/Components/Input/Input";
import Button from "../../Common/Components/Button/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Constants from "../../Utils/Constants";
import Context from "../../store/Contex";
import Loading from "../../Common/Components/Loading/Loading";
import { isEmailValid, passwordValidation } from "../../setup/Validate";
import style from "./style.module.css";

const Register = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);

  const Form = () => {
    const [formState, setFormState] = useState({
      details: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        referrer: "",
      },
      isRequesting: false,
      loadText: "",
      error: "",
      showPasswords: false,
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        general: "",
      },
    });
    const [showReferralInput, setShowReferralInput] = useState(false);

    const [referralText, setReferralText] = useState("Add Referral Code");

    const handleToggleReferral = () => {
      if (showReferralInput) {
        // If refund input is shown, hide it and change text to "Add Refund Address"
        setShowReferralInput(false);
        setReferralText("Add Referral Code");
      } else {
        // If refund input is hidden, show it and change text to "Remove Refund Address"
        setShowReferralInput(true);
        setReferralText("Remove Referral Code");
      }
    };
    const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isFull()) {
        setFormState({
          ...formState,
          isRequesting: true,
          loadText: "Creating Account",
        });
        const query = new URLSearchParams(window.location.search);
        const referrer = query.get("referrer");

        let data = {
          firstName: formState.details.firstName,
          lastName: formState.details.lastName,
          email: formState.details.email,
          password: formState.details.password,
          referrer: formState.details.referrer,
        };
        if (referrer) {
          data.referrer = referrer;
        }

        axios
          .post(`${Constants.API_BASE_URL}/auth/user/register`, data)
          .then((res) => {
            // console.log(res)
            setFormState({ ...formState, loadText: "Account Created" });
            setTimeout(() => {
              setFormState({ ...formState, isRequesting: false });
            }, 2000);

            let email = res.data.emailIdentity;
            let userInfo = {
              emailIdentity: {
                email: email?.email,
                emailVerified: email?.emailVerified,
              },
              user: {
                ...res.data.user,
                id: "",
                updatedAt: "",
                paymentMethod: "",
                paymentMethodId: "",
              },
              paymentMethod: {
                ...res.data.user.paymentMethod,
                updatedAt: "",
                id: "",
              },
              roles: res.data.roles,
            };
            let tokens = {
              token: res.data?.token,
              refreshToken: res.data?.refreshToken,
            };

            dispatch({ type: "TOKENS", payload: tokens });
            dispatch({ type: "USER_INFO", payload: userInfo });

            localStorage.setItem(
              "tokens",
              JSON.stringify({
                token: res.data?.token,
                refreshToken: res.data?.refreshToken,
              }),
            );

            // let signupUrl = "/register";
            // if (userInfo.user.referralCode) {
            //   signupUrl += `?referrer=${userInfo.user.referralCode}`;
            // }
            // window.location.href = signupUrl;

            window.location.href = `/email-verification/check/${formState.details.email}`;
          })
          .catch((e) => {
            setFormState({
              ...formState,
              isRequesting: false,
              error: e?.response?.data?.message,
              loadText: "Failed",
            });
            setTimeout(() => {
              setFormState({ ...formState, isRequesting: false, loadText: "" });
            }, 2000);
          });
      }
    };

    const isFull = () => {
      if (!isEmailValid(formState.details.email)) {
        setFormState((prev) => {
          return {
            ...prev,
            errors: { ...prev.errors, email: "Email is not valid" },
          };
        });
      }
      if (passwordValidation(formState.details.password).length > 1) {
        setFormState((prev) => {
          return {
            ...prev,
            errors: {
              ...prev.errors,
              password: "Password does not follow  format",
            },
          };
        });
      }
      if (formState.details.firstName.length < 3) {
        setFormState((prev) => {
          return {
            ...prev,
            errors: {
              ...prev.errors,
              firstName: "First name should contain more than 2 characters",
            },
          };
        });
      }
      if (formState.details.lastName.length < 3) {
        setFormState((prev) => {
          return {
            ...prev,
            errors: {
              ...prev.errors,
              lastName: "Last name should contain more than 2 characters",
            },
          };
        });
      }

      if (
        formState.details.lastName.length < 3 ||
        formState.details.firstName.length < 3 ||
        passwordValidation(formState.details.password).length > 1 ||
        !isEmailValid(formState.details.email) ||
        formState.details.password.length < 8
      ) {
        return false;
      }
      return true;
    };
    const confirmPassword = () => {
      if (
        formState.details.confirmPassword.length > 5 &&
        formState.details.password === formState.details.confirmPassword
      ) {
        setFormState((prev) => {
          return { ...prev, errors: { ...prev.errors, confirmPassword: "" } };
        });
        return true;
      } else if (
        formState.details.confirmPassword.length > 5 &&
        formState.details.password !== formState.details.confirmPassword
      ) {
        setFormState((prev) => {
          return {
            ...prev,
            errors: {
              ...prev.errors,
              confirmPassword: "Passwords do not match",
            },
          };
        });
        return false;
      } else {
        setFormState((prev) => {
          return { ...prev, errors: { ...prev.errors, confirmPassword: "" } };
        });
      }
    };

    useEffect(() => {
      confirmPassword();
      // eslint-disable-next-line
    }, [formState.details.confirmPassword]);

    return (
      <form
        action=""
        onSubmit={(e) => submitForm(e)}
        className={`pt-[5rem] mb-[1.6rem] w-[80%] ${style.formWidth} ${style.loginForm} h-full`}
      >
        {formState.isRequesting && <Loading loadText="" />}
        <div className="flex flex-col items-center cus-lg:items-start mb-14">
          <h1 className={`text-4xl font-bold`}>Register</h1>
          <b className={`font-normal mt-3`}>Sign up with your email address.</b>
        </div>

        <div
          className={`flex flex-col a-i-s w-full pl-1 cus-md:pr-8 md:pr-1 ${style.formDetails}`}
        >
          {formState.error && (
            <b className="text-red-500 font-normal">{formState.error}</b>
          )}

          <section className="sm:flex a-i-s md:my-1 w-full">
            <div className="flex flex-col items-start mr-3 w-full">
              <h1 className={`text-gray-800 text-left`}>First Name</h1>
              {formState.errors.firstName.length > 0 && (
                <div className="text-red-500 w-full text-sm text-left">
                  {formState.errors.firstName}
                </div>
              )}
              <Input
                style={{ minWidth: "100%" }}
                type="text"
                placeholder="Enter first name"
                setValue={(val) =>
                  setFormState({
                    ...formState,
                    details: { ...formState.details, firstName: val },
                  })
                }
                value={formState.details.firstName}
                className={`bg-white`}
              />
            </div>
            <div className="md:flex flex-col items-start w-full">
              <h1 className={`text-gray-800 text-left`}>Last Name </h1>
              {formState.errors.lastName.length > 0 && (
                <div className="text-red-500 w-full text-sm text-left">
                  {formState.errors.lastName}
                </div>
              )}
              <Input
                style={{ minWidth: "100%" }}
                type="text"
                placeholder="Enter last name"
                setValue={(val) =>
                  setFormState({
                    ...formState,
                    details: { ...formState.details, lastName: val },
                  })
                }
                value={formState.details.lastName}
                className={`bg-white`}
              />
            </div>
          </section>
          <section className="md:flex a-i-s md:my-1 w-full">
            <div className="flex flex-col items-start mr-3 w-full">
              <h1 className={`text-gray-800`}>Email</h1>
              {formState.errors.email.length > 0 ? (
                <div className="text-red-500 w-full text-sm text-left">
                  {formState.errors.email}
                </div>
              ) : (
                <>
                  {formState.details.email.length > 4 &&
                    !isEmailValid(formState.details.email) && (
                      <div className="text-red-500 w-full text-sm text-left">
                        Invalid Email Format
                      </div>
                    )}
                </>
              )}
              <Input
                style={{ minWidth: "100%" }}
                type="text"
                placeholder="Enter email address"
                setValue={(val) =>
                  setFormState({
                    ...formState,
                    details: { ...formState.details, email: val },
                  })
                }
                value={formState.details.email}
                className={`bg-white`}
              />
            </div>
            <div className="flex flex-col items-start mr-3 w-full relative">
              <div className="flex">
                <i
                  onClick={() =>
                    setFormState((prev) => {
                      return { ...prev, showPasswords: !prev.showPasswords };
                    })
                  }
                  className={`text-gray-500 cursor-pointer fa fa-eye${!formState.showPasswords ? "-slash" : ""} mr-2`}
                ></i>
                <h1 className={`text-gray-800`}>New Password</h1>
              </div>
              {formState.errors.password.length > 0 && (
                <div className="text-red-500 w-full text-sm text-left">
                  {formState.errors.password}
                </div>
              )}
              <Input
                style={{ minWidth: "100%" }}
                type={`${formState.showPasswords ? "text" : "password"}`}
                placeholder="Create new password"
                setValue={(val) =>
                  setFormState({
                    ...formState,
                    details: { ...formState.details, password: val },
                  })
                }
                value={formState.details.password}
                className={`bg-white`}
              />
              <small
                className={`text-left ${passwordValidation(formState.details.password).length > 1 && formState.details.password.length > 4 && "text-red-600"}`}
              >
                Use 8 or more characters with a mix of letters,numbers &
                symbols.
              </small>
            </div>
          </section>

          {/* referral */}

          {showReferralInput && (
            <section className="md:flex a-i-s md:my-1 w-full">
              <div className="flex flex-col items-start mr-3 w-full">
                <h1 className={`text-gray-800`}>Referral Code</h1>
                {/* {formState.errors.email.length > 0 ? (
                      <div className="text-red-500 w-full text-sm text-left">
                        {formState.errors.email}
                      </div>
                    ) : (
                      <>
                        {formState.details.email.length > 4 &&
                          !isEmailValid(formState.details.email) && (
                            <div className="text-red-500 w-full text-sm text-left">
                              Invalid Email Format
                            </div>
                          )}
                      </>
                    )} */}
                <Input
                  style={{ minWidth: "50%" }}
                  type="text"
                  placeholder="Enter referral code "
                  setValue={(val) =>
                    setFormState({
                      ...formState,
                      details: { ...formState.details, referrer: val },
                    })
                  }
                  value={formState.details.referrer}
                  className={`bg-white`}
                />
              </div>
            </section>
          )}
          <div
            className="text-blue text-xs underline cursor-pointer"
            onClick={handleToggleReferral}
          >
            {referralText}
          </div>
        </div>

        <div className="flex flex-col cus-lg:items-start mt-8">
          <b className="text-gray-500 text-[0.85rem] font-normal mb-2 text-center">
            By creating an account I agree to HodlPay's{" "}
            <a
              href={`https://hodlpay.io/terms-and-conditions`}
              rel="noreferrer"
              target="_blank"
              className="font-normal text-blue text-[0.85rem] cursor-pointer"
            >
              Terms and conditions.
            </a>
          </b>

          <Button
            text={"Create Account"}
            onClick={() => {}}
            className={`bg-blue text-white w-[180px] h-[50px] my-5`}
          />

          <small className="text-gray-500">
            Already have an Account?{" "}
            <b
              className="font-normal text-blue text-[0.85rem] cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Sign in here
            </b>
          </small>
        </div>
      </form>
    );
  };

  return (
    <Wrapper
      form={<Form />}
      sideImage={<SideImage mainImage={authImage} logo={logo2} />}
    />
  );
};

export default Register;
